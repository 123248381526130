import { handleActions } from 'redux-actions';
import { NotificationManager } from "react-notifications";
import { api } from "api";

const MODULO = 'MODULO';
const LOADER = 'LOADER';
const LISTADO_STATUS = 'LISTADO_STATUS';

const setLoader = loader => ({
    type: LOADER,
    loader,
});


export const listarStatus = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let params = { id };
    dispatch(setLoader(true));
    api.get('/registro_marca/status', params).then((response)=>{
        const statu = [];
        response.forEach(item => {
            statu.push({
                value: item.id,
                label: item.status
            })
        })
        dispatch({ type: LISTADO_STATUS, listStatus: statu });
    }).catch(()=>{
        NotificationManager.error(
            'Ocurrió un error al listar estatus',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const reporteMarcas = () => (dispatch, getStore) => {
    let data = getStore().form.FormInformesRegistroMarca.values;
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let reporte = 'listado_marcas';
    let status = '';
    if (data === undefined){
        status = '';
    }else{
        if (data.status === '0'){
            status = ''
        }else{
            status = data.status;
        }
    }
    const params = { id, reporte, status };
    dispatch(setLoader(true));
    api.getPdf('/registro_marca/registro_marca', params).then((response) => {
        if (response.headers['content-type'] == 'application/pdf') {
            var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'reporte_listado_marcas.pdf';
            window.open(link, '_blank');
            return false;
        }
    }).catch((error) => {
        if (error.status === 404) {
            NotificationManager.info(
                error.body.msj,
                'Informacion',
                5000
            );
        }
        if (error.status === 500) {
            NotificationManager.error(
                'Ocurrió un error al generar reporte',
                'Error',
                0
            );
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = { id, modulo }
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response) => {
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const actions = {
    reporteMarcas,
    getModulo,
    listarStatus
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [LISTADO_STATUS]: (state, { listStatus}) => {
        return {
            ...state,
            listStatus
        }
    }
};

export const initialState = {
    loader: false,
    modulo: [],
    listStatus: []
};

export default handleActions(reducers, initialState)