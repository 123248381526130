import React, { Component } from 'react';
import Formulario from './NominaOtrosForm';
import { withRouter } from 'react-router-dom';

class NominaOtros extends Component{
    
    componentDidMount = () => {
        const { getModulo, listar, leerPeriodoNomina, history,
            listarColaborador } = this.props;
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        getModulo(modulo[5],modulo[4]);
        leerPeriodoNomina(modulo[5],modulo[7]);
        listarColaborador();
        listar(history);
    }
    render(){
        const { loader, data, modulo, periodoN, correlativo, fecha_inicio, 
            fecha_fin, frecuencia, periodoD, periodoG, registroNomina,
            periodoId, reporteNomina, confirmarNomina, revisarNomina, 
            emp, eliminarLinea
            } = this.props;
        
        return(
            <Formulario
                loader = {loader}
                modulo = {modulo}
                data = {data}
                periodoN = {periodoN}
                correlativo = {correlativo}
                fecha_inicio = {fecha_inicio}
                fecha_fin = {fecha_fin}
                frecuencia = {frecuencia}
                periodoD = {periodoD}
                periodoG = {periodoG}
                periodoId = {periodoId}
                reporteNomina = {reporteNomina}
                confirmarNomina = {confirmarNomina}
                revisarNomina = {revisarNomina}
                emp = {emp}
                registroNomina = {registroNomina}
                eliminarLinea = {eliminarLinea}
            />
        );
    }
}
export default withRouter(NominaOtros);
