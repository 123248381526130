import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';
const valEmp = __CONFIG__.empresa;

const COLABORADOR = 'COLABORADOR';
const LISTADO = 'LISTADO';
const TIPO = 'TIPO';
const PUESTO = 'PUESTO';
const LOADER = 'LOADER';
const MODULO = 'MODULO';
const FECHA_SUS = 'FECHA_SUS';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listarColaborador = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let params;
    let id = ''
    if (valEmp === 'fage') {
        id = id_emp[6];
        params = { id };
    } else {
        id = id_emp[5];
        params = { id };
    }
    api.get('/rrhh_baja_igss/listar_bajas_igss', params).then(data => {
        const lista = [];
        if (data) {
            data.forEach(item => {
                lista.push({
                    value: item.colaborador,
                    label: item.codigo + ' - ' + item.nombre
                })
            })
        }
        dispatch({ type: COLABORADOR, emp: lista })
    }).catch((error) => {
        return [];
    })
}

export const datosColaborador = (emp) => (dispatch) => {
    api.get(`/colaborador/${emp}`).then((response) => {
        dispatch({ type: PUESTO, cargo: response.cargo })
        let params = { id: emp };
        api.get('/rrhh_baja_igss/fecha_baja_igss/', params).then((response) => {
            dispatch({ type: FECHA_SUS, fechaS: response.fecha })
        }).catch((error) => {
            return [];
        })
    }).catch((error) => {
        return [];
    })
}

export const registroAlta = (fechaS) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormAltaIgss.values;
    let id_empresa = '';
    if (valEmp === 'fage') {
        id_empresa = id_emp[6]
    } else {
        id_empresa = id_emp[5]
    }
    if (data === undefined) {
        Swal.fire({
            title: 'Alta de IGSS',
            text: 'Debe de seleccionar colaborador',
            type: 'error',
        })
    } else {
        if (data.fecha_alta <= fechaS) {
            Swal.fire({
                title: 'Alta de IGSS',
                text: 'La fecha de alta no puede ser menor o igual a la fecha de baja',
                type: 'error',
            })
        }else{
            if (data.fecha_alta === undefined || data.fecha_alta === '') {
                Swal.fire({
                    title: 'Alta de IGSS',
                    text: 'Debe de ingresar fecha de alta',
                    type: 'error',
                })
            } else {
                const formData = {
                    colaborador: data.colaborador.value,
                    fecha_alta: data.fecha_alta,
                    fecha_suspension: fechaS,
                    observaciones: data.observaciones,
                    descuento: data.descuento === false ? 'False' : 'True',
                    empresa: id_empresa
                }
                dispatch(setLoader(true));
                api.post('/rrhh_alta_igss/', formData).then(() => {
                    NotificationManager.success(
                        'Baja de IGSS creado correctamente',
                        'Exito',
                        3000
                    );
                    let ruta = '';
                    if (valEmp === 'fage') {
                        ruta = `/lfg/rr_hh/${id_emp[6]}/alta_igss`
                        dispatch(push(ruta));
                    } else {
                        ruta = `/rr_hh/${id_emp[5]}/alta_igss`
                        dispatch(push(ruta));
                    }
                }).catch((error) => {
                    NotificationManager.error(
                        error.msj,
                        'Error',
                        0
                    );
                }).finally(() => {
                    dispatch(setLoader(false));
                });
            }
        }

    }
}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = { id, modulo }
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response) => {
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = '';
    let params;
    if (valEmp === 'fage') {
        id = id_emp[6];
        params = { id };
    } else {
        id = id_emp[5];
        params = { id };
    }
    return api.get('/rrhh_alta_igss/', params).then((response) => {
        dispatch({ type: LISTADO, data: response })
    }).catch(error => {
        NotificationManager.error(
            'Ocurrió un error al listar altas de IGSS',
            'Error',
            0
        );
    })
}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/rrhh_alta_igss/${id}`).then((response) => {
        dispatch({ type: TIPO, tipo: response.motivo })
        dispatch(initializeForm('FormAltaIgss', response));
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar alta de IGSS',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const modificarAlta = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const datos = getStore().form.FormAltaIgss.values;
    const id = datos.id;
    const formData = {
        id: id,
        fecha_alta: datos.fecha_alta,
        observaciones: datos.observaciones,
        motivo: datos.motivoS ? datos.motivoS : datos.motivo,
        descuento: datos.descuento === false ? 'False' : 'True',
    }
    dispatch(setLoader(true));
    api.put(`/rrhh_alta_igss/${id}/`, formData).then(() => {
        NotificationManager.success(
            'Alta de IGSS modificada correctamente',
            'Exito',
            3000
        );
        let ruta = '';
        if (valEmp === 'fage') {
            ruta = `/lfg/rr_hh/${id_emp[6]}/alta_igss`
            dispatch(push(ruta));
        } else {
            ruta = `/rr_hh/${id_emp[5]}/alta_igss`
            dispatch(push(ruta));
        }
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al modificar alta de IGSS',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const limpiarDatos = () => (dispatch) => {
    dispatch({ type: PUESTO, cargo: [] });
};

const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`/rrhh_alta_igss/${id}`).then(() => {
        NotificationManager.success(
            'Alta de IGSS eliminado correctamente',
            'Exito',
            3000
        );
        dispatch(listar());
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error al eliminar alta de IGSS',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}


export const actions = {
    listarColaborador,
    getModulo,
    registroAlta,
    modificarAlta,
    datosColaborador,
    listar,
    leer,
    limpiarDatos,
    eliminar
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [COLABORADOR]: (state, { emp }) => {
        return {
            ...state,
            emp,
        };
    },
    [PUESTO]: (state, { cargo }) => {
        return {
            ...state,
            cargo,
        };
    },
    [LISTADO]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [TIPO]: (state, { tipo }) => {
        return {
            ...state,
            tipo,
        };
    },
    [FECHA_SUS]: (state, { fechaS }) => {
        return {
            ...state,
            fechaS,
        };
    },
};

export const initialState = {
    loader: false,
    modulo: [],
    data: [],
    emp: [],
    tipo: [],
    cargo: [],
    fechaS: ''
};

export default handleActions(reducers, initialState)
