import React, { Component } from 'react';
import Formulario from './InformesForm';

class Informes extends Component{
    componentDidMount = () => {
        const { getModulo, listarStatus } = this.props;
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        getModulo(modulo[5],modulo[4]);
        listarStatus();
    }
    render(){
        const { loader, modulo, reporteMarcas, listStatus } = this.props;
        return(
            <div>
                <Formulario
                    loader = {loader}
                    modulo = {modulo}
                    reporteMarcas = {reporteMarcas}
                    estatus = {listStatus}
                />
            </div>
        );
    }
}

export default Informes;
