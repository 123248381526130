import React, { Component } from 'react';
import Formulario from './ProductoForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";

class Producto extends Component{
    state = {
        crear: true,
    }

    componentDidMount = () => {
        const { match, leer, getModulo, listarPrincipios,
            limpiarDatos } = this.props;
        const id = match.params.id;
        if (id){
            this.setState({crear: false});
            leer(id);
        }
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        getModulo(modulo[5],modulo[4])
        localStorage.removeItem('id_detalle_producto_rm');
        listarPrincipios();
        limpiarDatos();
    }
    
    render(){
        const { registroProducto, modificarProducto, loader, modulo,
            lectura, listaPrincipio, detallePro, agregarLinea, eliminarLinea,
            detalleProducto, agregarLineaProducto, eliminarLineaProducto } = this.props;
        const { crear } = this.state;
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    crear = {crear}
                    modulo = {modulo}
                    lectura = {lectura}
                    obtenerPrincipio = {listaPrincipio}
                    detallePro = {detallePro}
                    agregarLinea = {agregarLinea}
                    registroProducto = {registroProducto}
                    modificarProducto = {modificarProducto}
                    eliminarLinea = {eliminarLinea}
                    detalleProducto = {detalleProducto}
                    agregarLineaProducto = {agregarLineaProducto}
                    eliminarLineaProducto = {eliminarLineaProducto}
                />
            </LoadMask>
        );
    }
}


export default Producto