import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';
import moment from 'moment';

const LISTADO_ENTRADA = 'LISTADO_ENTRADA';
const GUARDAR_ENTRADA = 'GUARDAR_ENTRADA';
const LOADER = 'LOADER';
const MODULO = 'MODULO';
const PERIODO_D = 'PERIODO_D';
const LISTADO_BODEGAS = 'LISTADO_BODEGAS';
const DETALLE_PRODUCTOS = 'DETALLE_PRODUCTOS';
const PARAMETRO = 'PARAMETRO';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let periodo = id_emp[7];
    let motivo = 'ING'
    //const params = { page, id, periodo, motivo };
    const params = { id, periodo, motivo };
    api.get('/movimiento_inventario', params).then((response)=>{
        dispatch({ type: LISTADO_ENTRADA, data: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar entradas',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/movimiento_inventario/${id}`).then((response) => {
        dispatch({type: GUARDAR_ENTRADA, lectura: response });
        localStorage.setItem('id_detalle_inventario_entrada', response.id);
        dispatch(initializeForm('FormEntradasInventario', response ));
        dispatch(leerDetalleProductos(response.id));
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar la entrada',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        if (error.status === 401){
            NotificationManager.error(
              error.body.msj,
              'Error',
              0
            );
        }
        if (error.status === 400){
            NotificationManager.error(
              'Ocurrió un error en buscar modulo',
              'Error',
              0
            );
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const leerPeriodoD = (id, id_periodo) => (dispatch) => {
    const params = { id, id_periodo };
    api.get('/periodos/periodo', params).then((response) => {
        dispatch({ type: PERIODO_D, periodoD: response.periodo });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar periodo',
            'Error',
            2000
        );
    })
}

export const listarBodegas = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    api.get('/bodega', params).then((response) => {
        const bodega = [];
        response.forEach(item=>{
            bodega.push({
                value: item.id,
                label: item.numero + ' / ' + item.bodega
            })
        })
        dispatch({ type: LISTADO_BODEGAS, listaBodegas: bodega });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar bodegas',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const listarProductos = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id, search}
    return api.get('/producto/empresa_productos_cod',params).then(data=>{
        const list_producto = [];
        if(data){
            data.forEach(item=>{
                if (item.tipo === 'P'){
                    list_producto.push({
                        value: item.id,
                        label: item.SKU + ' / ' + item.producto
                    })
                }
            })
        }
        return list_producto ;
    }).catch((error)=>{
        return [];
    })
}

export const registroProductoInventario = (periodoD, datos) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormEntradasInventario.values;
    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fecha)
    if (datos.farmaceutica === 'S'){
        if (fecha>=fechaI && fecha<=fechaF){
            if (data.lote === undefined || data.lote === ''){
                Swal.fire({
                    title: 'Entrada de inventario',
                    text: 'Debe de ingresar lote',
                    type: 'error',
                })
            }else{
                if (data.fecha_vencimiento === undefined || data.fecha_vencimiento === ''){
                    Swal.fire({
                        title: 'Entrada de inventario',
                        text: 'Debe de ingresar fecha de vencimiento',
                        type: 'error',
                    })
                }else{
                    if (data.cantidad === undefined || data.cantidad === ''){
                        Swal.fire({
                            title: 'Entrada de inventario',
                            text: 'Debe de ingresar cantidad',
                            type: 'error',
                        })
                    }else{
                        if (data.precio === undefined || data.precio === ''){
                            Swal.fire({
                                title: 'Entrada de inventario',
                                text: 'Debe de ingresar precio unitario',
                                type: 'error',
                            })
                        }else{
                            if (data.bodega === undefined || data.bodega === ''){
                                Swal.fire({
                                    title: 'Entrada de inventario',
                                    text: 'Debe de seleccionar bodega',
                                    type: 'error',
                                })
                            }else{
                                if (data.producto === undefined || data.producto === ''){
                                    Swal.fire({
                                        title: 'Entrada de inventario',
                                        text: 'Debe de seleccionar producto',
                                        type: 'error',
                                    })
                                }else{
                                    const detalle = localStorage.getItem('id_detalle_inventario_entrada');
                                    let totalPro = parseFloat(parseFloat(data.cantidad)*parseFloat(data.precio)).toFixed(2)
                                    const formData = {
                                        bodega: data.bodega,
                                        serie: data.serie,
                                        numero: data.numero,
                                        producto: data.producto.value,
                                        cantidad: data.cantidad,
                                        precio_unitario: data.precio,
                                        total: totalPro,
                                        id_compra: '',
                                        fecha: data.fecha,
                                        costo_unitario_s_iva: 0.00,
                                        total_s_iva: 0.00,
                                        motivo: 'ING',
                                        descripcion: data.descripcion,
                                        empresa: id_emp[5],
                                        tipoDoc: '',
                                        periodo: id_emp[7],
                                        detalle: detalle,
                                        lote: data.lote ? data.lote : '',
                                        fecha_vencimiento: data.fecha_vencimiento ? data.fecha_vencimiento : '',
                                    }
                                    dispatch(setLoader(true));
                                    api.post('/movimiento_inventario/', formData).then((response) => {
                                        if (detalle){
                                            response.serie = response.encabezado.serie;
                                            response.numero = response.encabezado.numero;
                                            response.fecha = response.encabezado.fecha;
                                            response.descripcion = response.encabezado.descripcion;
                                            response.bodega = response.detalle.bodega
                                            dispatch(initializeForm('FormEntradasInventario', response));
                                            dispatch(leerDetalleProductos(response.encabezado.id));
                                        }else{
                                            response.serie = response.encabezado.serie;
                                            response.numero = response.encabezado.numero;
                                            response.fecha = response.encabezado.fecha;
                                            response.descripcion = response.encabezado.descripcion;
                                            response.bodega = response.detalle.bodega
                                            dispatch(initializeForm('FormEntradasInventario', response));
                                            localStorage.setItem('id_detalle_inventario_entrada', response.encabezado.id);
                                            dispatch(leerDetalleProductos(response.encabezado.id));
                                        }
                                        NotificationManager.success(
                                            'Producto ingresado correctamente',
                                            'Exito',
                                            3000
                                        );
                                    }).catch((error) => {
                                        NotificationManager.error(
                                            error.msj,
                                            'Error',
                                            0
                                        );
                                    }).finally(() => {
                                        dispatch(setLoader(false));
                                    });
                                }
                            }
                        }
                    }
                }
            }
        }else {
            Swal.fire({
                title: 'Entrada de inventario',
                text: 'Fecha fuera del periodo',
                type: 'error',
            })        
        }
    }
    if (datos.farmaceutica === 'N'){
        if (fecha>=fechaI && fecha<=fechaF){
            if (data.cantidad === undefined || data.cantidad === ''){
                Swal.fire({
                    title: 'Entrada de inventario',
                    text: 'Debe de ingresar cantidad',
                    type: 'error',
                })
            }else{
                if (data.precio === undefined || data.precio === ''){
                    Swal.fire({
                        title: 'Entrada de inventario',
                        text: 'Debe de ingresar precio unitario',
                        type: 'error',
                    })
                }else{
                    if (data.bodega === undefined || data.bodega === ''){
                        Swal.fire({
                            title: 'Entrada de inventario',
                            text: 'Debe de seleccionar bodega',
                            type: 'error',
                        })
                    }else{
                        if (data.producto === undefined || data.producto === ''){
                            Swal.fire({
                                title: 'Entrada de inventario',
                                text: 'Debe de seleccionar producto',
                                type: 'error',
                            })
                        }else{
                            const detalle = localStorage.getItem('id_detalle_inventario_entrada');
                            let totalPro = parseFloat(parseFloat(data.cantidad)*parseFloat(data.precio)).toFixed(2)
                            const formData = {
                                bodega: data.bodega,
                                serie: data.serie,
                                numero: data.numero,
                                producto: data.producto.value,
                                cantidad: data.cantidad,
                                precio_unitario: data.precio,
                                total: totalPro,
                                id_compra: '',
                                fecha: data.fecha,
                                costo_unitario_s_iva: 0.00,
                                total_s_iva: 0.00,
                                motivo: 'ING',
                                descripcion: data.descripcion,
                                empresa: id_emp[5],
                                tipoDoc: '',
                                periodo: id_emp[7],
                                detalle: detalle,
                                lote: data.lote ? data.lote : '',
                                fecha_vencimiento: data.fecha_vencimiento ? data.fecha_vencimiento : '',
                            }
                            dispatch(setLoader(true));
                            api.post('/movimiento_inventario/', formData).then((response) => {
                                if (detalle){
                                    response.serie = response.encabezado.serie;
                                    response.numero = response.encabezado.numero;
                                    response.fecha = response.encabezado.fecha;
                                    response.descripcion = response.encabezado.descripcion;
                                    response.bodega = response.detalle.bodega
                                    dispatch(initializeForm('FormEntradasInventario', response));
                                    dispatch(leerDetalleProductos(response.encabezado.id));
                                }else{
                                    response.serie = response.encabezado.serie;
                                    response.numero = response.encabezado.numero;
                                    response.fecha = response.encabezado.fecha;
                                    response.descripcion = response.encabezado.descripcion;
                                    response.bodega = response.detalle.bodega
                                    dispatch(initializeForm('FormEntradasInventario', response));
                                    localStorage.setItem('id_detalle_inventario_entrada', response.encabezado.id);
                                    dispatch(leerDetalleProductos(response.encabezado.id));
                                }
                                NotificationManager.success(
                                    'Producto ingresado correctamente',
                                    'Exito',
                                    3000
                                );
                            }).catch((error) => {
                                NotificationManager.error(
                                    error.msj,
                                    'Error',
                                    0
                                );
                            }).finally(() => {
                                dispatch(setLoader(false));
                            });
                        }
                    }
                }
            }
        }else {
            Swal.fire({
                title: 'Entrada de inventario',
                text: 'Fecha fuera del periodo',
                type: 'error',
            })        
        }
    }
}

const leerDetalleProductos = id => (dispatch) => {
    api.get(`/movimiento_inventario_detalle/${id}`).then((response)=>{
        dispatch({ type: DETALLE_PRODUCTOS, detalleProducto: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};

const eliminarLineaProducto = id => (dispatch) => {
    const detalle = localStorage.getItem('id_detalle_inventario_entrada');
    dispatch(setLoader(true));
    api.eliminar(`/movimiento_inventario_detalle/${id}`).then(() => {
        dispatch(leerDetalleProductos(detalle));
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const parametrosEmpresa = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let params = {id}
    dispatch(setLoader(true));
    api.get('/empresa/empresa_parametros', params).then((response) => {
        dispatch({type: PARAMETRO, datos: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar parametros',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const imprimirEntrada = (id_mov) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let reporte = 'imprimirEntrada';
    const params = { id, reporte, id_mov };
    dispatch(setLoader(true));
    api.getPdf('/movimiento_inventario', params).then((response)=>{
        if (response.headers['content-type'] == 'application/pdf'){
            var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'entrada_inventario.pdf';
            window.open(link, '_blank');
            return false;
        }
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al generar reporte',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const confirmarEntrada = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let idInv = localStorage.getItem('id_detalle_inventario_entrada')
    if (idInv) {
        localStorage.removeItem('id_detalle_inventario_entrada');
        NotificationManager.success(
            'Entrada de inventario guardada correctamente',
            'Exito',
            3000
        );
        Swal.fire({
            title: '¿Imprimir entrada?',
            text: '¡Desea imprimir ingreso de inventario!',
            type: 'question',
            showCancelButton: true,
            confirmButtonText: '¡Sí, aceptar!',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                let id = id_emp[5];
                let reporte = 'imprimirEntrada';
                let id_mov = idInv;
                const params = { id, reporte, id_mov };
                dispatch(setLoader(true));
                api.getPdf('/movimiento_inventario', params).then((response)=>{
                    const editar = window.location.href.includes('editar');
                    if (editar){
                        ruta = `/inventario/${id_emp[5]}/entradas/${id_emp[7]}`
                        dispatch(push(ruta));
                    }else{
                        window.location.reload(true);
                    }
                    if (response.headers['content-type'] == 'application/pdf'){
                        var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
                        var link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = 'entrada_inventario.pdf';
                        window.open(link, '_blank');
                        return false;
                    }
                }).catch((error)=>{
                    NotificationManager.error(
                        'Ocurrió un error al generar reporte',
                        'Error',
                        2000
                    );
                }).finally(() => {
                    dispatch(setLoader(false));
                });
                
            }else {
                const editar = window.location.href.includes('editar');
                if (editar){
                    ruta = `/inventario/${id_emp[5]}/entradas/${id_emp[7]}`
                    dispatch(push(ruta));
                }else{
                    window.location.reload(true);
                }
            }
        });
    }else {
        Swal.fire({
            title: 'Entrada de inventario',
            text: 'No hay productos ingresados',
            type: 'error',
        })
    }
}


export const actions = {
    listar,
    leer,
    getModulo,
    leerPeriodoD,
    listarBodegas,
    listarProductos,
    registroProductoInventario,
    leerDetalleProductos,
    eliminarLineaProducto,
    confirmarEntrada,
    parametrosEmpresa,
    imprimirEntrada
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_ENTRADA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [GUARDAR_ENTRADA]: (state, { lectura }) => {
        return {
            ...state,
            lectura,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [PERIODO_D]: (state, { periodoD }) => {
        return {
            ...state,
            periodoD,
        };
    },
    [LISTADO_BODEGAS]: (state, { listaBodegas }) => {
        return {
            ...state,
            listaBodegas,
        };
    },
    [DETALLE_PRODUCTOS]: (state, { detalleProducto }) => {
        return {
            ...state,
            detalleProducto,
        };
    },
    [PARAMETRO]: (state, { datos }) => {
        return {
            ...state,
            datos,
        };
    },
};

export const initialState = {
    loader: false,
    lectura: [],
    modulo: [],
    periodoD: [],
    detalleProducto: [],
    listaBodegas: [],
    data: [],
    page: 1,
    datos: [],
};

export default handleActions(reducers, initialState)
