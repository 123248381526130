import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
const valEmp = __CONFIG__.empresa;

const LISTADO_LIMITACION = 'LISTADO_LIMITACION';
const LOADER = 'LOADER';
const MODULO = 'MODULO';
const AGENTE = 'AGENTE';
const PAIS = 'PAIS';
const OBSERVACIONES = 'OBSERVACIONES';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let params;
    let id = ''
    if (valEmp === 'fage'){
        id = id_emp[6];
        params = { id };
    }else{
        id = id_emp[5];
        params = { id };
    }
    dispatch(setLoader(true));
    api.get('/registro_marca/limitacion', params).then((response)=>{
        dispatch({ type: LISTADO_LIMITACION, data: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar limitación',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const registroObservacion = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormObservacionRM.values;
    let id_empresa = id_emp[5]
    
    const formData = {
        fecha: data.fecha,
        marca: data.marca.value,
        observacion: data.observacion,
        empresa: id_empresa
    }
    dispatch(setLoader(true));
    api.post('/registro_marca/observaciones/', formData).then(() => {
        NotificationManager.success(
        'Observación creado',
        'Exito',
        3000
    );
    window.location.reload(true);
    }).catch((error) => {
    NotificationManager.error(
        error.msj,
        'Error',
        2000
    );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/registro_marca/limitacion/${id}`).then((response) => {
        dispatch(initializeForm('FormLimitacionRM', response ));
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar limitación',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        if (error.status === 401){
            NotificationManager.error(
              error.body.msj,
              'Error',
              0
            );
        }
        if (error.status === 400){
            NotificationManager.error(
              'Ocurrió un error en buscar modulo',
              'Error',
              0
            );
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const listarMarcas = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id, search}
    return api.get('/registro_marca/registro_marca/marcas',params).then(data=>{
        const list_marca = [];
        if(data){
            data.forEach(item=>{
                list_marca.push({
                    value: item.id,
                    label: item.productoN + ' -- ' + item.agenteNombre + ' / ' + item.paisNombre
                })
            })
        }
        return list_marca;
    }).catch((error)=>{
        return [];
    })
}

const datosMarca = id => (dispatch) => {
    api.get(`/registro_marca/observaciones/${id}`).then((response)=>{
        dispatch({ type: OBSERVACIONES, detalleObs: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};

export const limpiarDatos = () => (dispatch) => {
    dispatch({ type: OBSERVACIONES, detalleObs: [] });
}

export const actions = {
    listar,
    registroObservacion,
    leer,
    getModulo,
    listarMarcas,
    datosMarca,
    limpiarDatos

};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_LIMITACION]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [AGENTE]: (state, { agente }) => {
        return {
            ...state,
            agente,
        };
    },
    [PAIS]: (state, { pais }) => {
        return {
            ...state,
            pais,
        };
    },
    [OBSERVACIONES]: (state, { detalleObs }) => {
        return {
            ...state,
            detalleObs,
        };
    },
};

export const initialState = {
    loader: false,
    modulo: [],
    data: [],
};

export default handleActions(reducers, initialState)
 