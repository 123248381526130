import React, { useState } from 'react';
import Modal from '../components/Modal';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LoadMask from "../Utils/LoadMask/LoadMask";
import NavbarModulo from '../components/NavbarModulo';

import {
    SelectField,
    renderFieldRadio,
    renderNumber,
    AsyncSelectField,
    renderDayPickerB,
    SelectFieldReporte
} from "../Utils/renderField/renderField";


function InformesForm(props) {
    const {
        modulo,
        loader,
        reporteListadoColaboradores,
        reporteBirthdays,
        obtenerPaises,
        excelReporteEmpleador,
        reporteRecibosPeriodo,
        listarColaborador,
        listarPlanillasRecibos,
        reportePromedioIngresos,
        listaPeriodo,
        reporteListadoFamilia,
        excelListadoFamilia,
        listarColaboradorTodos,
        reporteListadoPermisos,
        reporteSuspendidosIGSS,
        reportePrestamos,
        excelListadoColaboradores,
        deptos,
        reporteLiquidacionISR,
        excelLiquidacionISR,
        reporteISR
    } = props;
    
    const EstadoSelectOptions = [
        { value: 'TODOS', label: 'TODOS' },
        { value: 'ACTIVOS', label: 'ACTIVOS' },
        { value: 'BAJA', label: 'BAJA' },
    ]

    const meses = [
        { value: 1, label: 'ENERO' },
        { value: 2, label: 'FEBRERO' },
        { value: 3, label: 'MARZO' },
        { value: 4, label: 'ABRIL' },
        { value: 5, label: 'MAYO' },
        { value: 6, label: 'JUNIO' },
        { value: 7, label: 'JULIO' },
        { value: 8, label: 'AGOSTO' },
        { value: 9, label: 'SEPTIEMBRE' },
        { value: 10, label: 'OCTUBRE' },
        { value: 11, label: 'NOVIEMBRE' },
        { value: 12, label: 'DICIEMBRE' }
    ]

    const [modalListadoColaboradores, setModalListadoColaboradores] = useState(false);
    const [modalListadoBirthdays, setModalListadoBirthdays] = useState(false);
    const [modalInformeEmpleador, setModalInformeEmpleador] = useState(false);
    const [opcion, setOpcion] = useState("CODIGO");
    const [opcionSus, setOpcionSus] = useState("activos");
    const [modalRecibosMes, setModalRecibosMes] = useState(false);
    const [modalPromedioIngreso, setModalPromedioIngreso] = useState(false);
    const [modalListadoFam, setModalListadoFam] = useState(false);
    const [modalListadoPermisos, setModalListadoPermisos] = useState(false);
    const [modalListadoSuspendidos, setModalListadoSuspendidos] = useState(false);
    const [modalListadoPrestamos, setModalListadoPrestamos] = useState(false);
    const [modalLiquidacionISR, setModalLiquidacionISR] = useState(false);
    const [modalISRAnual, setModalISRAnual] = useState(false);
    const [opcionPres, setOpcionPres] = useState("activos");
        
    let titulo = 'Informes'
    let ruta = window.location.href;
    let id_emp = ruta.split('/');

    const onOptionChange = e => {
        setOpcion(e.target.value)
    }
    
    const handleOnChangeRecibo = (e,value) => {
        if (value.length == 4) {
            listarPlanillasRecibos(value)
        }
        
    }

    const onOptionChangeSus = e => {
        setOpcionSus(e.target.value)
    }

    const onOptionChangePres = e => {
        setOpcionPres(e.target.value)
    }

    let rutaInforme = `/#/rr_hh/${id_emp[5]}/informes`

    return (
        <div>
           <NavbarModulo
                modulo={modulo}
                titulo={titulo}
           />
        
            <div className="container">
                <br/>
                <h4 className='mt-1' style={{ color: "white" }}>Informes</h4>
                <div className="row">
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <a class="list-group-item list-group-item-action"
                                id="list-home-list"
                                onClick={() => setModalListadoColaboradores(!modalListadoColaboradores)}
                                data-bs-toggle="list"
                                role="tab"
                                href={rutaInforme}
                                aria-controls="list-home">Listado de colaboradores</a>
                        </div>
                    </div>
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <a class="list-group-item list-group-item-action"
                                id="list-home-list"
                                onClick={() => setModalListadoBirthdays(!modalListadoBirthdays)}
                                data-bs-toggle="list"
                                role="tab"
                                href={rutaInforme}
                                aria-controls="list-home">Listado de cumpleañeros</a>
                        </div>
                    </div>
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <a class="list-group-item list-group-item-action"
                                id="list-home-list"
                                onClick={() => setModalInformeEmpleador(!modalInformeEmpleador)}
                                data-bs-toggle="list"
                                role="tab"
                                href={rutaInforme}
                                aria-controls="list-home">Informe de empleador</a>
                        </div>
                    </div>
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <a class="list-group-item list-group-item-action"
                                id="list-home-list"
                                onClick={() => setModalListadoFam(!modalListadoFam)}
                                data-bs-toggle="list"
                                role="tab"
                                href={rutaInforme}
                                aria-controls="list-home">Listado de padre/madre</a>
                        </div>
                    </div>
                </div>
                <div className="row mt-1">
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <a class="list-group-item list-group-item-action"
                                id="list-home-list"
                                onClick={() => setModalListadoPermisos(!modalListadoPermisos)}
                                data-bs-toggle="list"
                                role="tab"
                                href={rutaInforme}
                                aria-controls="list-home">Listado de permisos / faltas</a>
                        </div>
                    </div>
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <a class="list-group-item list-group-item-action"
                                id="list-home-list"
                                onClick={() => setModalListadoSuspendidos(!modalListadoPermisos)}
                                data-bs-toggle="list"
                                role="tab"
                                href={rutaInforme}
                                aria-controls="list-home">Listado de suspendidos IGSS</a>
                        </div>
                    </div>
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <a class="list-group-item list-group-item-action"
                                id="list-home-list"
                                onClick={() => setModalListadoPrestamos(!modalListadoPrestamos)}
                                data-bs-toggle="list"
                                role="tab"
                                href={rutaInforme}
                                aria-controls="list-home">Listado de préstamos</a>
                        </div>
                    </div>
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <a class="list-group-item list-group-item-action"
                                id="list-home-list"
                                onClick={() => setModalLiquidacionISR(!modalLiquidacionISR)}
                                data-bs-toggle="list"
                                role="tab"
                                href={rutaInforme}
                                aria-controls="list-home">Liq. anual ISR</a>
                        </div>
                    </div>
                </div>
                <div className="row mt-1">
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <a class="list-group-item list-group-item-action"
                                id="list-home-list"
                                onClick={() => setModalISRAnual(!modalISRAnual)}
                                data-bs-toggle="list"
                                role="tab"
                                href={rutaInforme}
                                aria-controls="list-home">ISR Anual</a>
                        </div>
                    </div>
                </div>
                <h4 className='mt-5' style={{ color: "white" }}>Informes de nómina</h4>
                <div className="row mt-2">
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <a class="list-group-item list-group-item-action"
                                id="list-home-list"
                                onClick={() => setModalRecibosMes(!modalRecibosMes)}
                                data-bs-toggle="list"
                                role="tab"
                                href={rutaInforme}
                                aria-controls="list-home">Recibos de nómina</a>
                        </div>
                    </div>
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <a class="list-group-item list-group-item-action"
                                id="list-home-list"
                                onClick={() => setModalPromedioIngreso(!modalPromedioIngreso)}
                                data-bs-toggle="list"
                                role="tab"
                                href={rutaInforme}
                                aria-controls="list-home">Promedio de ingresos</a>
                        </div>
                    </div>
                </div>
                <Modal
                    estado={modalListadoColaboradores} //Listado de colaboradores
                    cambiarEstado={setModalListadoColaboradores}
                    titulo="Listado de colaboradores"
                    botonCerrar="true"
                    >
                        <Contenido>
                        <div className='col-12 mb-2'>
                            {obtenerPaises &&
                                <div className="form-floating">
                                    <Field
                                        name="pais"
                                        options={obtenerPaises}
                                        crear={true}
                                        dato={0}
                                        component={SelectField}
                                        className="form-select"
                                        msj="Pais"
                                    />
                                </div>
                            }
                        </div>
                        <div className='col-12 mb-2'>
                            <Field
                                name="estado"
                                placeholder="Estado..."
                                className="form-control-find"
                                component={SelectField}
                                options={EstadoSelectOptions}
                                msj="Estado"
                            />
                        </div>
                        <div className='d-flex flex-column m-1'>
                            
                            <Field
                                type="radio"
                                name="ordenarPor"
                                label="CODIGO"
                                value="CODIGO"
                                checked={opcion === "CODIGO"}
                                component={renderFieldRadio}
                                onChange={onOptionChange}
                                />
                            <Field
                                type="radio"
                                name="ordenarPor"
                                label="CARGO"
                                value="CARGO"
                                checked={opcion === "CARGO"}
                                component={renderFieldRadio}
                                onChange={onOptionChange}
                                />
                        </div>
                        </Contenido>
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={reporteListadoColaboradores}
                                    >
                                    Generar Reporte
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={excelListadoColaboradores}
                                    >
                                    Exportar Excel
                                    </button>
                                </div>
                                <button
                                    className='btn mr-2 mb-3 btn btn-secondary'
                                    onClick={() => setModalListadoColaboradores(false)}
                                >
                                Cancelar
                                </button>
                            </Contenido>
                        </LoadMask>
                </Modal>  
                <Modal
                    estado={modalListadoBirthdays} //Listado de cumpleañeros
                    cambiarEstado={setModalListadoBirthdays}
                    titulo="Listado de cumpleañeros"
                    botonCerrar="true"
                    >
                        <Contenido>
                        <div className='col-12 mb-2'>
                            {obtenerPaises &&
                                <div className="form-floating">
                                    <Field
                                        name="pais"
                                        options={obtenerPaises}
                                        crear={true}
                                        dato={0}
                                        component={SelectField}
                                        className="form-select"
                                        msj="Pais"
                                    />
                                </div>
                            }
                        </div>
                        <div className='col-12 mb-5'>
                            <Field
                                name="bDayMes"
                                placeholder="Mes..."
                                className="form-control-find"
                                component={SelectField}
                                options={meses}
                                msj="Mes"
                            />
                        </div>
                        </Contenido>
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div className='row'>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={reporteBirthdays}
                                    >
                                    Generar Reporte
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn btn-secondary'
                                        onClick={() => setModalListadoBirthdays(false)}
                                    >
                                    Cancelar
                                    </button>
                                </div>
                            </Contenido>
                        </LoadMask>
                </Modal>  
                <Modal
                    estado={modalInformeEmpleador} //Informe empleador
                    cambiarEstado={setModalInformeEmpleador}
                    titulo="Informe de empleador"
                    botonCerrar="true"
                >
                    <Contenido>
                        <div className='col-4 mb-2'>
                            <div className="form-floating">
                                <Field
                                    name="anio"
                                    placeholder="Año"
                                    className="form-control"
                                    component={renderNumber}
                                    msj="Año"
                                />
                            </div>
                        </div>
                        <LoadMask loading={loader} dark>
                            <div className='mt-2'>
                                <button
                                    className='btn mr-2 mb-3 btn-primary'
                                    onClick={excelReporteEmpleador}
                                >
                                Generar excel
                                </button>
                                <button
                                    className='btn mr-2 mb-3 btn btn-secondary'
                                    onClick={() => setModalInformeEmpleador(false)}
                                >
                                   Cancelar
                                </button>
                            </div>
                        </LoadMask>
                    </Contenido>
                </Modal>  
                <Modal
                    estado={modalRecibosMes} //Recibos nomina
                    cambiarEstado={setModalRecibosMes}
                    titulo="Recibos de nómina"
                    botonCerrar="true"
                    >
                        <Contenido>
                            <div className='col-12 mb-2'>
                                <Field
                                    name="colaborador"
                                    placeholder="Colaborador..."
                                    className="form-control-find"
                                    component={AsyncSelectField}
                                    loadOptions={listarColaborador}
                                    msj="Colaborador"
                                />
                            </div>
                            <div className='col-4 mb-2'>
                                <Field
                                    name="year"
                                    placeholder="Año"
                                    className="form-control"
                                    component={renderNumber}
                                    onChange={handleOnChangeRecibo}
                                    msj="Año"
                                />
                            </div>
                            {listaPeriodo &&
                                <div className='col-12 mb-3'>
                                    <Field
                                        name="periodo"
                                        options={listaPeriodo}
                                        component={SelectField}
                                        className="form-select"
                                        msj="Periodos"
                                    />
                                </div>
                            }
                            {deptos &&
                                <div className='col-12 mb-3'>
                                    <Field
                                        name="departamento"
                                        options={deptos}
                                        component={SelectFieldReporte}
                                        className="form-select"
                                        msj="Departamento"
                                    />
                                </div>
                            }
                        </Contenido>
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div className='row'>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={reporteRecibosPeriodo}
                                    >
                                    Generar Reporte
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn btn-secondary'
                                        onClick={() => setModalRecibosMes(false)}
                                    >
                                    Cancelar
                                    </button>
                                </div>
                            </Contenido>
                        </LoadMask>
                </Modal>  
                <Modal
                    estado={modalPromedioIngreso} //Promedio de ingresos
                    cambiarEstado={setModalPromedioIngreso}
                    titulo="Promedio de ingresos"
                    botonCerrar="true"
                    >
                        <Contenido>
                            <div className='col-12 mb-2'>
                                <Field
                                    name="colaborador"
                                    placeholder="Colaborador..."
                                    className="form-control-find"
                                    component={AsyncSelectField}
                                    loadOptions={listarColaboradorTodos}
                                    msj="Colaborador"
                                />
                            </div>
                            <div className='row col-12 mb-2'>
                                <div className='col-6'>
                                    <div className="form-floating">
                                        <Field
                                            name="mes_inicial"
                                            options={meses}
                                            component={SelectField}
                                            className="form-select"
                                            msj="Mes inicial"
                                        />
                                    </div>
                                </div>
                                <div className='col-4'>
                                    <div className="form-floating">
                                        <Field
                                            name="year_inicial"
                                            component={renderNumber}
                                            className="form-control"
                                            msj="Año inicial"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='row col-12 mb-3'>
                                <div className='col-6'>
                                    <div className="form-floating">
                                        <Field
                                            name="mes_final"
                                            options={meses}
                                            component={SelectField}
                                            className="form-select"
                                            msj="Mes final"
                                        />
                                    </div>
                                </div>
                                <div className='col-4'>
                                    <div className="form-floating">
                                        <Field
                                            name="year_final"
                                            component={renderNumber}
                                            className="form-control"
                                            msj="Año final"
                                        />
                                    </div>
                                </div>
                            </div>
                        </Contenido>
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div className='row'>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={reportePromedioIngresos}
                                    >
                                    Generar Reporte
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn btn-secondary'
                                        onClick={() => setModalPromedioIngreso(false)}
                                    >
                                    Cancelar
                                    </button>
                                </div>
                            </Contenido>
                        </LoadMask>
                </Modal>
                <Modal
                    estado={modalListadoFam} //Listado de padre/madre
                    cambiarEstado={setModalListadoFam}
                    titulo="Listado de padre/madre"
                    botonCerrar="true"
                    >
                        <Contenido>
                            <div className='col-12 mb-3'>
                                {obtenerPaises &&
                                    <div className="form-floating">
                                        <Field
                                            name="pais"
                                            options={obtenerPaises}
                                            crear={true}
                                            dato={0}
                                            component={SelectField}
                                            className="form-select"
                                            msj="Pais"
                                        />
                                    </div>
                                }
                            </div>
                            <LoadMask loading={loader} dark>
                                <div className='mt-2'>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={reporteListadoFamilia}
                                    >
                                        Generar reporte
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={excelListadoFamilia}
                                    >
                                        Exportar excel
                                    </button>
                                </div>    
                            </LoadMask>
                            <button
                                className='btn mr-2 mb-3 btn btn-secondary'
                                onClick={() => setModalListadoFam(false)}
                            >
                                Cancelar
                            </button>
                        </Contenido>
                </Modal>    
                <Modal
                    estado={modalListadoPermisos} //Reporte de permisos
                    cambiarEstado={setModalListadoPermisos}
                    titulo="Listado de permisos / faltas"
                    botonCerrar="true"
                    >
                        <Contenido>
                            <div className='row'>
                                <div className='col-6 mb-2'>
                                    <Field
                                        name="fechaInicial"
                                        component={renderDayPickerB}
                                        msj="Fecha del"
                                            
                                    />
                                </div>
                                <div className='col-6 mb-2'>
                                    <Field
                                        name="fechaFinal"
                                        component={renderDayPickerB}
                                        msj="Fecha al"
                                            
                                    />
                                </div>
                            </div>
                            <div className='col-12 mb-2'>
                                <Field
                                    name="colaborador"
                                    placeholder="Colaborador..."
                                    className="form-control-find"
                                    component={AsyncSelectField}
                                    loadOptions={listarColaboradorTodos}
                                    msj="Colaborador"
                                />
                            </div>
                        </Contenido>
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div className='row'>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={reporteListadoPermisos}
                                    >
                                    Generar Reporte
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn btn-secondary'
                                        onClick={() => setModalListadoPermisos(false)}
                                    >
                                    Cancelar
                                    </button>
                                </div>
                            </Contenido>
                        </LoadMask>
                </Modal>
                <Modal
                    estado={modalListadoSuspendidos} //Reporte de suspendidos
                    cambiarEstado={setModalListadoSuspendidos}
                    titulo="Listado de suspendidos IGSS"
                    botonCerrar="true"
                    >
                        <Contenido>
                            <div className='row'>
                                <div className='col-6 mb-2'>
                                    <Field
                                        name="fechaInicial"
                                        component={renderDayPickerB}
                                        msj="Fecha del"
                                            
                                    />
                                </div>
                                <div className='col-6 mb-2'>
                                    <Field
                                        name="fechaFinal"
                                        component={renderDayPickerB}
                                        msj="Fecha al"
                                            
                                    />
                                </div>
                            </div>
                            <div className='col-12 mb-2'>
                                <Field
                                    name="colaborador"
                                    placeholder="Colaborador..."
                                    className="form-control-find"
                                    component={AsyncSelectField}
                                    loadOptions={listarColaboradorTodos}
                                    msj="Colaborador"
                                />
                            </div>
                            <div className='d-flex flex-column m-1'>
                                <Field
                                    type="radio"
                                    name="tipoReporte"
                                    label="Activos"
                                    value="activos"
                                    checked={opcionSus === "activos"}
                                    component={renderFieldRadio}
                                    onChange={onOptionChangeSus}
                                    />
                                <Field
                                    type="radio"
                                    name="tipoReporte"
                                    label="Historial"
                                    value="historial"
                                    checked={opcionSus === "historial"}
                                    component={renderFieldRadio}
                                    onChange={onOptionChangeSus}
                                    />
                            </div>
                        </Contenido>
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div className='row'>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={reporteSuspendidosIGSS}
                                    >
                                    Generar Reporte
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn btn-secondary'
                                        onClick={() => setModalListadoSuspendidos(false)}
                                    >
                                    Cancelar
                                    </button>
                                </div>
                            </Contenido>
                        </LoadMask>
                </Modal>
                <Modal
                    estado={modalListadoPrestamos} //Reporte de prestamos
                    cambiarEstado={setModalListadoPrestamos}
                    titulo="Listado de préstamos"
                    botonCerrar="true"
                    >
                        <Contenido>
                            <div className='row'>
                                <div className='col-6 mb-2'>
                                    <Field
                                        name="fechaInicial"
                                        component={renderDayPickerB}
                                        msj="Fecha del"
                                            
                                    />
                                </div>
                                <div className='col-6 mb-2'>
                                    <Field
                                        name="fechaFinal"
                                        component={renderDayPickerB}
                                        msj="Fecha al"
                                            
                                    />
                                </div>
                            </div>
                            <div className='col-12 mb-2'>
                                <Field
                                    name="colaborador"
                                    placeholder="Colaborador..."
                                    className="form-control-find"
                                    component={AsyncSelectField}
                                    loadOptions={listarColaboradorTodos}
                                    msj="Colaborador"
                                />
                            </div>
                            <div className='d-flex flex-column m-1'>
                                <Field
                                    type="radio"
                                    name="tipoReportePrestamo"
                                    label="Activos"
                                    value="activos"
                                    checked={opcionPres === "activos"}
                                    component={renderFieldRadio}
                                    onChange={onOptionChangePres}
                                    />
                                <Field
                                    type="radio"
                                    name="tipoReportePrestamo"
                                    label="Cancelados"
                                    value="cancelados"
                                    checked={opcionPres === "cancelados"}
                                    component={renderFieldRadio}
                                    onChange={onOptionChangePres}
                                    />
                            </div>
                        </Contenido>
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div className='row'>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={reportePrestamos}
                                    >
                                    Generar Reporte
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn btn-secondary'
                                        onClick={() => setModalListadoPrestamos(false)}
                                    >
                                    Cancelar
                                    </button>
                                </div>
                            </Contenido>
                        </LoadMask>
                </Modal>
                <Modal
                    estado={modalLiquidacionISR} //Liquidacion anual ISR
                    cambiarEstado={setModalLiquidacionISR}
                    titulo="Liquidación anual ISR"
                    botonCerrar="true"
                >
                    <Contenido>
                        <div className='col-4 mb-3'>
                            <div className="form-floating">
                                <Field
                                    name="anio"
                                    placeholder="Año"
                                    className="form-control"
                                    component={renderNumber}
                                    msj="Año"
                                />
                            </div>
                        </div>
                        <LoadMask loading={loader} dark>
                            <div className='mt-2'>
                                <button
                                    className='btn mr-2 mb-3 btn-primary'
                                    onClick={reporteLiquidacionISR}
                                >
                                    Generar reporte
                                </button>
                                <button
                                    className='btn mr-2 mb-3 btn-primary'
                                    onClick={excelLiquidacionISR}
                                >
                                    Exportar excel
                                </button>
                            </div> 
                        </LoadMask>
                        <button
                            className='btn mb-3 btn-secondary'
                            onClick={() => setModalLiquidacionISR(false)}
                        >
                            Cancelar
                        </button>
                    </Contenido>
                </Modal>  
                <Modal
                    estado={modalISRAnual} //ISR anual 
                    cambiarEstado={setModalISRAnual}
                    titulo="ISR anual"
                    botonCerrar="true"
                >
                    <Contenido>
                        <div className='col-12 mb-2'>
                            <Field
                                name="colaborador"
                                placeholder="Colaborador..."
                                className="form-control-find"
                                component={AsyncSelectField}
                                loadOptions={listarColaboradorTodos}
                                msj="Colaborador"
                            />
                        </div>
                        <div className='col-4 mb-2'>
                            <Field
                                name="year"
                                placeholder="Año"
                                className="form-control"
                                component={renderNumber}
                                onChange={handleOnChangeRecibo}
                                msj="Año"
                            />
                        </div>
                    </Contenido>
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div className='row'>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={reporteISR}
                                    >
                                        Generar reporte
                                    </button>
                                    <button
                                        className='btn mb-3 btn-secondary'
                                        onClick={() => setModalISRAnual(false)}
                                    >
                                        Cancelar
                                    </button>        
                                </div> 
                            </Contenido>
                        </LoadMask>
                </Modal>  
            </div>
        </div>
    );
}

InformesForm.propTypes = {
    loader: PropTypes.bool,
    modulo: PropTypes.object,
    listarProyectos: PropTypes.func,
    reporteDetalleGastosProyecto: PropTypes.func,
};

export default reduxForm({
    form: 'FormInformesRRHH',
    initialValues: {
        estado: 'TODOS',
        ordenarPor: 'CODIGO',
    }           
})(InformesForm);


const Contenido = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`;
