import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';

const LISTADO_PRODUCTO = 'LISTADO_PRODUCTO';
const GUARDAR_PRODUCTO = 'GUARDAR_PRODUCTO';
const LOADER = 'LOADER';
const MODULO = 'MODULO';
const LISTADO_PRINCIPIOS = 'LISTADO_PRINCIPIOS';
const DETALLE = 'DETALLE';
const DETALLE_PRODUCTOS = 'DETALLE_PRODUCTOS';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let params = { id };
    dispatch(setLoader(true));
    api.get('/registro_marca/producto', params).then((response) => {
        dispatch({ type: LISTADO_PRODUCTO, data: response });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error al listar productos',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const registroProducto = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormProductoRM.values;
    let id_empresa = id_emp[5]
    if (data === undefined) {
        Swal.fire({
            title: 'Producto',
            text: 'Debe de ingresar datos del producto',
            type: 'error',
        })
    } else {
        if (data.descripcion === undefined || data.descripcion === '') {
            Swal.fire({
                title: 'Producto',
                text: 'Debe de ingresar nombre del producto',
                type: 'error',
            })
        } else {
            if (data.detalleD === undefined || data.detalleD === '') {
                Swal.fire({
                    title: 'Producto',
                    text: 'Debe de ingresar detalle del producto',
                    type: 'error',
                })
            } else {
                const formData = {
                    descripcion: data.descripcion,
                    principio_activo: data.principio_activo,
                    detallePro: data.detalleD,
                    empresa: id_empresa
                }
                dispatch(setLoader(true));
                api.post('/registro_marca/producto/', formData).then(() => {
                    NotificationManager.success(
                        'Producto creado',
                        'Exito',
                        3000
                    );
                    let ruta = `/registro_marca/${id_emp[5]}/producto_marca`
                    dispatch(push(ruta));
                }).catch((error) => {
                    NotificationManager.error(
                        error.msj,
                        'Error',
                        2000
                    );
                }).finally(() => {
                    dispatch(setLoader(false));
                });
            }
        }
    }
}

export const modificarProducto = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const datos = getStore().form.FormProductoRM.values;
    const id = id_emp[7];
    
    if (datos === undefined) {
        Swal.fire({
            title: 'Producto',
            text: 'Debe de ingresar datos del producto',
            type: 'error',
        })
    } else {
        if (datos.descripcion === undefined || datos.descripcion === '') {
            Swal.fire({
                title: 'Producto',
                text: 'Debe de ingresar nombre del producto',
                type: 'error',
            })
        } else {
            const formData = {
                id: id,
                descripcion: datos.descripcion,
                linea: datos.linea,
                familia: datos.familia,
                farmaceutico: datos.farmaceutico,
                forma: datos.forma_farmaceutica,
                grupo: datos.grupo_terapeutico,
            }
            dispatch(setLoader(true));
            api.put(`/registro_marca/producto/${id}/`, formData).then(() => {
                NotificationManager.success(
                    'Producto modificado correctamente',
                    'Exito',
                    3000
                );
                let ruta = `/registro_marca/${id_emp[5]}/producto_marca`
                dispatch(push(ruta));
            }).catch((error) => {
                NotificationManager.error(
                    'Ocurrió un error al modificar producto',
                    'Error',
                    2000
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }
    }
}

const leerDetalleProductos = id => (dispatch) => {
    api.get(`/registro_marca/producto_detalle/${id}`).then((response) => {
        dispatch({ type: DETALLE_PRODUCTOS, detalleProducto: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/registro_marca/producto/${id}`).then((response) => {
        localStorage.setItem('id_detalle_producto_rm', response.id);
        dispatch(initializeForm('FormProductoRM', response));
        dispatch({ type: GUARDAR_PRODUCTO, lectura: response });
        dispatch(leerDetalleProductos(response.id));
    }).catch((error) => {

        NotificationManager.error(
            'Ocurrió un error al consultar producto',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = { id, modulo }
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response) => {
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const listarPrincipios = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let params = { id };
    return api.get("/registro_marca/principio_activo", params).then(response => {
        const principios = [];
        response.forEach(item => {
            principios.push({
                value: item.id + '/' + item.descripcion,
                label: item.descripcion
            })
        })
        dispatch({ type: LISTADO_PRINCIPIOS, listaPrincipio: principios });
    }).catch(error => {
        NotificationManager.error(
            'Ocurrió un error al listar principios activos',
            'Error',
            8000
        );
    })
}


const actualizar = () => (dispatch, getStore) => {
    const data = getStore().form.FormProductoRM.values;
    dispatch(initializeForm('FormProductoRM', data));
    dispatch({ type: DETALLE, detallePro: data.detalleD })
};


let detalle = [];
export const agregarLinea = () => (dispatch, getStore) => {
    const data = getStore().form.FormProductoRM.values;
    if (data.principio === undefined || data.principio === '') {
        Swal.fire({
            title: 'Producto',
            text: 'Debe de seleccionar principio activo',
            type: 'error',
        })
    } else {
        let datosPrincipio = data.principio.split('/');
        detalle.push({
            id: datosPrincipio[0],
            idPrincipio: datosPrincipio[0],
            principio: datosPrincipio[1],
        })
        data.detalleD = detalle;
        NotificationManager.success(
            'Producto ingresado correctamente',
            'Exito',
            3000
        );

        dispatch(actualizar());
    }
}

const eliminarLinea = (id) => (dispatch) => {
    let d = id.split('/');
    detalle.forEach((element, index) => {
        if (element.idPrincipio === d[0] && element.idConcentracion === d[1]) {
            detalle.splice(index, 1);
        }
    })
    dispatch(actualizar());
};

export const agregarLineaProducto = () => (dispatch, getStore) => {
    const data = getStore().form.FormProductoRM.values;
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    if (data.principio === undefined || data.principio === '') {
        Swal.fire({
            title: 'Producto',
            text: 'Debe de seleccionar principio activo',
            type: 'error',
        })
    } else {
        let datosPrincipio = data.principio.split('/');
    
        const formData = {
            idPrincipio: datosPrincipio[0],
            detalle: id_emp[7],
        }
        dispatch(setLoader(true));
        api.post('/registro_marca/producto/', formData).then((response) => {
            NotificationManager.success(
                'Producto ingresado correctamente',
                'Exito',
                3000
            );
            dispatch(leerDetalleProductos(response.detalle));
        }).catch((error) => {
            NotificationManager.error(
                error.msj,
                'Error',
                2000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
}


const eliminarLineaProducto = id => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    dispatch(setLoader(true));
    api.eliminar(`/registro_marca/producto_detalle/${id}`).then(() => {
        dispatch(leerDetalleProductos(id_emp[7]));
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar el producto',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const limpiarDatos = () => (dispatch) => {
    detalle = [];
    dispatch(initializeForm('FormProductoRM'));
    dispatch({ type: DETALLE, detallePro: detalle })
};

const eliminarProducto = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`/registro_marca/producto/${id}`).then(() => {
        NotificationManager.success(
            'Producto eliminado correctamente',
            'Exito',
            3000
        );
        dispatch(listar());
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar el producto',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const actions = {
    listar,
    registroProducto,
    modificarProducto,
    leer,
    getModulo,
    listarPrincipios,
    agregarLinea,
    eliminarLinea,
    agregarLineaProducto,
    eliminarLineaProducto,
    limpiarDatos,
    eliminarProducto
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_PRODUCTO]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [GUARDAR_PRODUCTO]: (state, { lectura }) => {
        return {
            ...state,
            lectura,
        };
    },
    [LISTADO_PRINCIPIOS]: (state, { listaPrincipio }) => {
        return {
            ...state,
            listaPrincipio,
        };
    },
    [DETALLE]: (state, { detallePro }) => {
        return {
            ...state,
            detallePro,
        };
    },
    [DETALLE_PRODUCTOS]: (state, { detalleProducto }) => {
        return {
            ...state,
            detalleProducto,
        };
    },
};

export const initialState = {
    loader: false,
    modulo: [],
    lectura: [],
    listaForm: [],
    data: [],
    listaPrincipio: [],
    detallePro: [],
    detalleProducto: [],
};

export default handleActions(reducers, initialState)
