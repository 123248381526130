import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';
import moment from 'moment';

const LISTADO_SALIDA = 'LISTADO_SALIDA';
const GUARDAR_SALIDA = 'GUARDAR_SALIDA';
const LOADER = 'LOADER';
const MODULO = 'MODULO';
const PERIODO_D = 'PERIODO_D';
const DETALLE_PRODUCTOS = 'DETALLE_PRODUCTOS';
const PARAMETRO = 'PARAMETRO';
const PRODUCTOS = 'PRODUCTOS';
const PRODUCTOS_FARM = 'PRODUCTOS_FARM';
const LOTES = 'LOTES';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let periodo = id_emp[7];
    let motivo = 'SAL'
    //const params = { page, id, periodo, motivo };
    const params = { id, periodo, motivo };
    api.get('/movimiento_inventario', params).then((response)=>{
        dispatch({ type: LISTADO_SALIDA, data: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar salidas',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/movimiento_inventario/${id}`).then((response) => {
        dispatch({type: GUARDAR_SALIDA, lectura: response });
        localStorage.setItem('id_detalle_inventario_salida', response.id);
        dispatch(initializeForm('FormSalidasInventario', response ));
        dispatch(leerDetalleProductos(response.id));
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar la salida',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const leerPeriodoD = (id, id_periodo) => (dispatch) => {
    const params = { id, id_periodo };
    api.get('/periodos/periodo', params).then((response) => {
        dispatch({ type: PERIODO_D, periodoD: response.periodo });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar periodo',
            'Error',
            2000
        );
    })
}

export const listarProductos = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id, search}
    return api.get('/producto/empresa_productos_cod_inventario',params).then(data=>{
        const list_producto = [];
        if(data){
            data.forEach(item=>{
                list_producto.push({
                    value: item.id+' # '+item.bodega+' # '+item.saldo,
                    label: item.sku + ' / ' + item.productoN + ' -- ' + ' Bodega: ' +item.numeroBodega + ' Existencia: ' + new Intl.NumberFormat("en-US", { minimumFractionDigits: 6 }).format(item.saldo)
                })
            })
        }
        return list_producto ;
    }).catch((error)=>{
        return [];
    })
}

export const registroProductoInventario = (periodoD, datos) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormSalidasInventario.values;
    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fecha)
    if (fecha>=fechaI && fecha<=fechaF){
        const detalle = localStorage.getItem('id_detalle_inventario_salida');
        let datos_p = data.producto.value;
        let datos_pro = datos_p.split('#');
        let exis = parseFloat(datos_pro[1].trim())
        if (datos.movimiento_s_existencia === 'N')
            if (parseFloat(data.cantidad)<=exis){
                let formData = ''
                if (datos.farmaceutica === 'N'){
                    formData = {
                        bodega: data.bodega.value,
                        serie: data.serie.toUpperCase(),
                        numero: data.numero,
                        producto: datos_pro[0].trim(),
                        cantidad: data.cantidad,
                        precio_unitario: 0.00,
                        total: 0.00,
                        id_compra: '',
                        fecha: data.fecha,
                        costo_unitario_s_iva: 0.00,
                        total_s_iva: 0.00,
                        motivo: 'SAL',
                        descripcion: data.descripcion,
                        empresa: id_emp[5],
                        tipoDoc: '',
                        periodo: id_emp[7],
                        detalle: detalle,
                    }
                }
                if (datos.farmaceutica === 'S'){
                    let loteDes = data.lote.label.split('/');
                    let lote = data.lote.value.split('#');
                    formData = {
                        bodega: data.bodega.value,
                        serie: data.serie.toUpperCase(),
                        numero: data.numero,
                        producto: datos_pro[0].trim(),
                        cantidad: data.cantidad,
                        precio_unitario: 0.00,
                        total: 0.00,
                        id_compra: '',
                        fecha: data.fecha,
                        costo_unitario_s_iva: 0.00,
                        total_s_iva: 0.00,
                        motivo: 'SAL',
                        descripcion: data.descripcion,
                        empresa: id_emp[5],
                        tipoDoc: '',
                        periodo: id_emp[7],
                        detalle: detalle,
                        lote: loteDes[0].trim(),
                        fecha_vencimiento: lote[3].trim(),
                    }
                }
                dispatch(setLoader(true));
                api.post('/movimiento_inventario/', formData).then((response) => {
                    if (detalle){
                        response.serie = response.encabezado.serie;
                        response.numero = response.encabezado.numero;
                        response.fecha = response.encabezado.fecha;
                        response.descripcion = response.encabezado.descripcion;
                        dispatch(listarProductos());
                        dispatch(initializeForm('FormSalidasInventario', response));
                        dispatch(leerDetalleProductos(response.encabezado.id));
                    }else{
                        response.serie = response.encabezado.serie;
                        response.numero = response.encabezado.numero;
                        response.fecha = response.encabezado.fecha;
                        response.descripcion = response.encabezado.descripcion;
                        dispatch(listarProductos());
                        localStorage.setItem('id_detalle_inventario_salida', response.encabezado.id);
                        dispatch(leerDetalleProductos(response.encabezado.id));
                        dispatch(initializeForm('FormSalidasInventario', response));
                    }
                    NotificationManager.success(
                        'Producto ingresado correctamente',
                        'Exito',
                        3000
                    );
                }).catch((error) => {
                    NotificationManager.error(
                        error.msj,
                        'Error',
                        0
                    );
                }).finally(() => {
                    dispatch(setLoader(false));
                });
            }else {
                Swal.fire({
                    title: 'Salida de inventario',
                    text: 'La cantidad ingresada es mayor a la existencia',
                    type: 'error',
                })        
            }
        
    }else {
        Swal.fire({
            title: 'Salida de inventario',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })        
    }
}

const leerDetalleProductos = id => (dispatch) => {
    api.get(`/movimiento_inventario_detalle/${id}`).then((response)=>{
        dispatch({ type: DETALLE_PRODUCTOS, detalleProducto: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};

const eliminarLineaProducto = id => (dispatch) => {
    const detalle = localStorage.getItem('id_detalle_inventario_salida');
    dispatch(setLoader(true));
    api.eliminar(`/movimiento_inventario_detalle/${id}`).then(() => {
        dispatch(leerDetalleProductos(detalle));
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const confirmarSalida = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let idInv = localStorage.getItem('id_detalle_inventario_salida')
    if (idInv) {
        localStorage.removeItem('id_detalle_inventario_salida');
        NotificationManager.success(
            'Salida de inventario guardada correctamente',
            'Exito',
            3000
        );
        Swal.fire({
            title: '¿Imprimir salida?',
            text: '¡Desea imprimir salida de inventario!',
            type: 'question',
            showCancelButton: true,
            confirmButtonText: '¡Sí, aceptar!',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                let id = id_emp[5];
                let reporte = 'imprimirSalida';
                let id_mov = idInv;
                const params = { id, reporte, id_mov };
                dispatch(setLoader(true));
                api.getPdf('/movimiento_inventario', params).then((response)=>{
                    const editar = window.location.href.includes('editar');
                    if (editar){
                        ruta = `/inventario/${id_emp[5]}/salidas/${id_emp[7]}`
                        dispatch(push(ruta));
                    }else{
                        window.location.reload(true);
                    }
                    if (response.headers['content-type'] == 'application/pdf'){
                        var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
                        var link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = 'entrada_inventario.pdf';
                        window.open(link, '_blank');
                        return false;
                    }
                }).catch((error)=>{
                    NotificationManager.error(
                        'Ocurrió un error al generar reporte',
                        'Error',
                        2000
                    );
                }).finally(() => {
                    dispatch(setLoader(false));
                });
                
            }else {
                const editar = window.location.href.includes('editar');
                if (editar){
                    ruta = `/inventario/${id_emp[5]}/salidas/${id_emp[7]}`
                    dispatch(push(ruta));
                }else{
                    window.location.reload(true);
                }
            }
        });
    }else {
        Swal.fire({
            title: 'Salida de inventario',
            text: 'No hay productos ingresados',
            type: 'error',
        })
    }
}

export const parametrosEmpresa = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id}
    dispatch(setLoader(true));
    api.get('/empresa/empresa_parametros', params).then((response) => {
        dispatch({type: PARAMETRO, datos: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar parametros',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const listarBodegas = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search };
    return api.get('/bodega', params).then((response) => {
        const bodega = [];
        if (response){
            response.forEach(item=>{
                bodega.push({
                    value: item.id,
                    label: item.numero + ' / ' + item.bodega
                })
            })
        }
        return bodega;
    }).catch((error) => {
        return [];
    })
}

export const leerProductos = (id, datos) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let idEmpre = id_emp[5];
    
    if (datos.agricola === 'S'){
        const params = {id, idEmpre }
        api.get('/producto/empresa_productos_cod_inventario_bodega_prd/',params).then((response) => {
            const producto = [];
            response.forEach(item=>{
                if (item.tipoPro === 'P'){
                    producto.push({
                        value: item.id+' # '+ item.saldo + '#' + item.tipoPro,
                        label: item.sku + ' / ' + item.productoN + ' -- ' + ' Existencia: ' + item.saldo
                    })
                }
            })
            dispatch({type: PRODUCTOS, pro: producto })
        })    
    
    }
    if (datos.agricola === 'N'){
        let form = 'F'
        const params = {id, idEmpre, form }
        api.get('/producto/empresa_productos_cod_inventario_bodega/',params).then((response) => {
            const producto = [];
            response.forEach(item=>{
                if (item.tipoPro === 'P'){
                    producto.push({
                        value: item.id+' # '+ item.saldo + '#' + item.tipoPro,
                        label: item.sku + ' / ' + item.productoN + ' -- ' + ' Existencia: ' + item.saldo
                    })
                }
            })
            dispatch({type: PRODUCTOS, pro: producto })
        })    
    }
    
}

export const leerProductosFarm = (id) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let idEmpre = id_emp[5];
    const params = {id, idEmpre }
    api.get('/producto/empresa_productos_cod_inventario_bodega_farm/',params).then((response) => {
        const producto = [];
        response.forEach(item=>{
            if (item.tipoPro === 'P'){
                producto.push({
                    value: item.id+' # '+ item.saldo + '#' + item.tipoPro + '#' + item.productoN,
                    label: item.sku + ' / ' + item.productoN + ' -- ' + ' Existencia: ' + new Intl.NumberFormat("en-US", { minimumFractionDigits: 6 }).format(item.saldo)
                })
            }
        })
        dispatch({type: PRODUCTOS_FARM, proFarm: producto })
    })
}

export const leerLotes = (id, bodega) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let idEmpre = id_emp[5];
    const params = {id, idEmpre, bodega }
    api.get('/producto/empresa_productos_cod_inventario_lotes_farm/',params).then((response) => {
        const lote = [];
        response.forEach(item=>{
            lote.push({
                value: item.id + '#' + item.saldo + '#' + moment(item.vencimiento).format("MM/YY") + '#' + moment(item.vencimiento).format("YYYY-MM-DD"),
                label: item.lote + ' / ' + moment(item.vencimiento).format("MM/YYYY") + ' -- ' + ' Existencia: ' + new Intl.NumberFormat("en-US", { minimumFractionDigits: 6 }).format(item.saldo)
            })
        })
        dispatch({type: LOTES, lotes: lote })
    })
}

export const imprimirSalida = (id_mov) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let reporte = 'imprimirSalida';
    const params = { id, reporte, id_mov };
    dispatch(setLoader(true));
    api.getPdf('/movimiento_inventario', params).then((response)=>{
        if (response.headers['content-type'] == 'application/pdf'){
            var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'salida_inventario.pdf';
            window.open(link, '_blank');
            return false;
        }
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al generar reporte',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const actions = {
    listar,
    leer,
    getModulo,
    leerPeriodoD,
    listarProductos,
    registroProductoInventario,
    leerDetalleProductos,
    eliminarLineaProducto,
    confirmarSalida,
    parametrosEmpresa,
    listarBodegas,
    leerProductos,
    leerProductosFarm,
    leerLotes,
    imprimirSalida
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_SALIDA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [GUARDAR_SALIDA]: (state, { lectura }) => {
        return {
            ...state,
            lectura,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [PERIODO_D]: (state, { periodoD }) => {
        return {
            ...state,
            periodoD,
        };
    },
    [DETALLE_PRODUCTOS]: (state, { detalleProducto }) => {
        return {
            ...state,
            detalleProducto,
        };
    },
    [PARAMETRO]: (state, { datos }) => {
        return {
            ...state,
            datos,
        };
    },
    [PRODUCTOS]: (state, { pro }) => {
        return {
            ...state,
            pro,
        };
    },
    [PRODUCTOS_FARM]: (state, { proFarm }) => {
        return {
            ...state,
            proFarm,
        };
    },
    [LOTES]: (state, { lotes }) => {
        return {
            ...state,
            lotes,
        };
    },
};

export const initialState = {
    loader: false,
    lectura: [],
    modulo: [],
    periodoD: [],
    datos: [],
    pro: [],
    detalleProducto: [],
    data: [],
    page: 1,
    proFarm: [],
    lotes: [],
};

export default handleActions(reducers, initialState)
