import React from 'react';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import * as BsIcons from "react-icons/bs";

export const SidebarData = [
    {
        title: 'Inicio',
        path: '/registro_marca',
        icon: <BsIcons.BsGrid1X2Fill />,
    },
    {
        title: 'Catalogos',
        path: '#',
        icon: <FaIcons.FaBook />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        subNav: [
            {
                title: 'Productos',
                path: '/registro_marca',
                path_s: 'producto_marca',
                icon: <FaIcons.FaDollyFlatbed />,
            },
            {
                title: 'Agente',
                path: '/registro_marca',
                path_s: 'agente',
                icon: <BsIcons.BsFillGridFill />,
            },
            {
                title: 'Estatus',
                path: '/registro_marca',
                path_s: 'status',
                icon: <BsIcons.BsCheckCircleFill />,
            },
            {
                title: 'Titular',
                path: '/registro_marca',
                path_s: 'titular',
                icon: <BsIcons.BsFillGridFill />,
            },
            {
                title: 'Comercializada',
                path: '/registro_marca',
                path_s: 'comercializada',
                icon: <BsIcons.BsFillGridFill />,
            },
            {
                title: 'Principio activo',
                path: '/registro_marca',
                path_s: 'principio_activo',
                icon: <BsIcons.BsFillGridFill />,
            },
        ]
    },
    {
        title: 'Registro de marca',
        path: '#',
        icon: <BsIcons.BsFileCheckFill />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        subNav: [
            {
                title: 'Registro de marca',
                path: '/registro_marca',
                path_s: 'marca',
                icon: <FaIcons.FaBook />,
            },
            {
                title: 'Observaciones',
                path: '/registro_marca',
                path_s: 'observaciones',
                icon: <FaIcons.FaClipboardCheck />,
            },
        ]
    },
    {
        title: 'Informes',
        label: 'informes',
        path: '/registro_marca/informes',
        icon: <FaIcons.FaClipboard />,
    }
]
